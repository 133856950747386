.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.divider-line {
  flex: 1;
  border-top: 2px solid #4d4d4d;
}

.divider-text {
  padding: 0 20px;
  font-weight: 600; /* Semi-bold */
  color: #4d4d4d;
}
